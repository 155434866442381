import React from 'react';
import styled from 'styled-components';

const InfoWrapper = styled.div`
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #666;
    text-align: center;
`;


const InfoTitle = styled.h4`
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const InfoText = styled.p`
    margin-bottom: 0.5rem;
`;

const InfoList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
`;

const InfoListItem = styled.li`
    margin-bottom: 0.3rem;
`;

const DentalHygieneInfo = () => (
    <InfoWrapper>
        <InfoTitle>Warum ist die Zahnhygiene bei Hunden so wichtig?</InfoTitle>
        <InfoText>
            Die Folgen einer schlechten oder mangelnden Zahnhygiene beim Hund sind weitreichend. Neben Maulgeruch, Zahnverlust, Zahnstein und Entzündungen, bis hin zu chronischen Maulhöhlenerkrankungen, können im schlimmsten Fall schweren Krankheiten führen kann.
        </InfoText>
        <InfoText>
            Durch eine regelmäßige und gründliche Zahnhygiene fallen Veränderungen im Maul schneller auf und können so schneller behandelt werden. Einer Zahnsteinentfernung kann bei ordnungsgemäßer und regelmäßiger Anwendung prophylaktisch vorgebeugt werden. Sie ersparen so Ihrer Fellnase wahrscheinlich eine Vollnarkose wegen Zahnsteinentfernung.
        </InfoText>
        <InfoText>
            Damit die Vierbeiner auch im hohen Alter ihre Zähne behalten und noch kraftvoll zubeißen können, ist die Gewöhnung an die richtige Maulpflege schon im Welpenalter zu empfehlen.
        </InfoText>
        <InfoTitle>Vorteile</InfoTitle>
        <InfoList>
            <InfoListItem>- Lautlose Reinigung ohne Angst – keine Vibration und kein Piep Ton</InfoListItem>
            <InfoListItem>- 100% Ultraschall</InfoListItem>
            <InfoListItem>- Hilft Zahnstein und Bakterien vorzubeugen und zu entfernen</InfoListItem>
            <InfoListItem>- Reinigt sanft, schmerzlos und gründlich</InfoListItem>
            <InfoListItem>- Bewegungslose Reinigung</InfoListItem>
            <InfoListItem>- Regt die Durchblutung im Zahnfleisch an</InfoListItem>
            <InfoListItem>- Das Ausfallen der Zähne kann vermieden werden.</InfoListItem>
        </InfoList>
        <InfoText>
            Die Zahnbürste muss auf jeden Fall vor der ersten Behandlung käuflich erworben werden. Diese wird bei BeDlu- Hundesalon eingelagert und nur für Ihren Hund verwendet.
        </InfoText>
        <InfoText>
            Achtung:
        </InfoText>
        <InfoText>
            Wenn Ihr Tier unter Zahnproblemen leidet, sollten Sie zuerst mit einem Tierarzt
            sprechen.
        </InfoText>
    </InfoWrapper>
);

export default DentalHygieneInfo;

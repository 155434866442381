import React from 'react';
import styled from 'styled-components';

const TestimonialsContainer = styled.div`
  background: #f9f9f9;
  padding: 2rem;
  text-align: center;
`;

const Testimonial = styled.div`
  margin: 1rem 0;
`;

const Testimonials = () => (
    <TestimonialsContainer>
        <h2>Was meine Kunden sagen</h2>
        <Testimonial>
            <p>"Beate kümmert sich total liebevoll um meinen Hund" - Marina</p>
        </Testimonial>
        <Testimonial>
            <p>"Mein Hund sieht nach jedem Besuch wundervoll aus." - Sebastian</p>
        </Testimonial>
    </TestimonialsContainer>
);

export default Testimonials;

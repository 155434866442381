import React from 'react';
import styled from "styled-components";
import "../styles/HomeCenterLogo.css";
import ImageSlider from "../components/ImageSlider";
import Testimonials from "../components/Testimonials";
import logo from '../assets/images/bedlu_logo.png';
import slider1 from '../assets/images/scheren_quer.jpg';
import slider2 from '../assets/images/visitenkarte.jpg';
import slider3 from '../assets/images/tisch_komplett.jpg';

const HomeTitle = styled.h1`
    text-align: center;
    font-size: 2rem;
`;

const HomeSubtitle = styled.p`
    text-align: center;
    font-size: 1.5rem;
`;

const Home = () => {
    const images = [slider1, slider2, slider3]

    return (
        <div>
            <main>
                <section>
                    <HomeTitle>Herzlich willkommen im BeDlu Hundesalon!</HomeTitle>
                    <div className="centered-image-container">
                        <img src={logo} alt="BeDlu Hundesalon" className="centered-image"/>
                    </div>
                    <HomeSubtitle>Bei uns bekommen sie den besten Service für ihren Hund</HomeSubtitle>
                </section>
            </main>
            <ImageSlider images={images}/>
            <Testimonials/>
        </div>
    );
};

export default Home;

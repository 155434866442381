import React from 'react';
import ServiceItem from './ServiceItem';
import styled from 'styled-components';
import DentalHygieneInfo from "./DentalHygieneInfo";
import ThalassoInfo from "./ThalassoInfo";
import PricingInfo from "./PricingInfo";
import FAQInfo from "./FAQInfo";

const Container = styled.div`
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
`;

const ServiceCategory = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ServiceItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const CategoryTitle = styled.h2`
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #333;
    text-align: center;
`;

const Subtitle = styled.p`
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #666;
    text-align: center;
`;

const ServiceList = () => (
    <Container>
        <hr/>
        <PricingInfo/>
        <hr/>
        <ServiceCategory>
            <CategoryTitle>Kleine Hunde</CategoryTitle>
            <Subtitle>z.B. Maltipoo, Bolonka, Malteser, Havaneser, Shi Tzu, Yorkshire Terrier, Chihuahua</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Waschen, Bürsten, Föhnen"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten ohne Schnitt"]}
                    price="Ab 35 Euro"
                />
                <ServiceItem
                    title="Professioneller Trockenschnitt"
                    services={["Bürsten und Kämmen", "Schneiden und Scheren", "inkl. Pfotenpflege und Ohrenreinigung"]}
                    price="Ab 37 Euro"
                />
                <ServiceItem
                    title="Premium Fellpflege"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten mit Schnitt", "Inkl. Pfotenpflege- und Ohrenreinigung"]}
                    price="Ab 57 Euro"
                />
                <ServiceItem
                    title="Professionelles Trimmen"
                    services={["Bürsten und Kämmen", "Trimmen und Schneiden", "Pfotenpflege und Ohrenreinigung"]}
                    price="Ab 67 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Mittlere Hunde</CategoryTitle>
            <Subtitle>z.B. Cocker Spaniel, Kleinpudel, Französiche Bulldogge, Zwergschnauzer, Cairn Terrier</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Waschen, Bürsten, Föhnen"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten ohne Schnitt"]}
                    price="Ab 45 Euro"
                />
                <ServiceItem
                    title="Professioneller Trockenschnitt"
                    services={["Bürsten und Kämmen", "Schneiden und Scheren", "inkl. Pfotenpflege und Ohrenreinigung"]}
                    price="Ab 47 Euro"
                />
                <ServiceItem
                    title="Premium Fellpflege"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten mit Schnitt", "Inkl. Pfotenpflege- und Ohrenreinigung"]}
                    price="Ab 67 Euro"
                />
                <ServiceItem
                    title="Professionelles Trimmen"
                    services={["Bürsten und Kämmen", "Trimmen und Schneiden", "Pfotenpflege und Ohrenreinigung"]}
                    price="Ab 77 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Große Hunde</CategoryTitle>
            <Subtitle>z.B. Bearded Collie, Australien Shepherd, Border Collie, Fox Terrier, Golden- und
                Labradoodel, Golden Retriever, Irish Terrier, Tibet Terrier, Mittelschnauzer</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Waschen, Bürsten, Föhnen"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten ohne Schnitt"]}
                    price="Ab 65 Euro"
                />
                <ServiceItem
                    title="Professioneller Trockenschnitt"
                    services={["Bürsten und Kämmen", "Schneiden und Scheren", "inkl. Pfotenpflege und Ohrenreinigung"]}
                    price="Ab 67 Euro"
                />
                <ServiceItem
                    title="Premium Fellpflege"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten mit Schnitt", "Inkl. Pfotenpflege- und Ohrenreinigung"]}
                    price="Ab 87 Euro"
                />
                <ServiceItem
                    title="Professionelles Trimmen"
                    services={["Bürsten und Kämmen", "Trimmen und Schneiden", "Pfotenpflege und Ohrenreinigung"]}
                    price="Ab 97 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Große XL Hunde</CategoryTitle>
            <Subtitle>z.B. Riesenschnauzer, Neufundländer, Irischer Wolfshund, Berner Sennenhund,
                Großpudel</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Waschen, Bürsten, Föhnen"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten ohne Schnitt"]}
                    price="Ab 95 Euro"
                />
                <ServiceItem
                    title="Professioneller Trockenschnitt"
                    services={["Bürsten und Kämmen", "Schneiden und Scheren", "inkl. Pfotenpflege und Ohrenreinigung"]}
                    price="Ab 97 Euro"
                />
                <ServiceItem
                    title="Premium Fellpflege"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten mit Schnitt", "Inkl. Pfotenpflege- und Ohrenreinigung"]}
                    price="Ab 120 Euro"
                />
                <ServiceItem
                    title="Professionelles Trimmen"
                    services={["Bürsten und Kämmen", "Trimmen und Schneiden", "Pfotenpflege und Ohrenreinigung"]}
                    price="Ab 140 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Einzelpflege</CategoryTitle>
            <Subtitle>Die Krallenpflege beinhaltet das Kürzen der Krallen, um ein Überwachsen zu
                verhindern und eine gesunde Haltung der Pfoten zu fördern. Dies ist wichtig für
                die Gesundheit und das Wohlbefinden des Hundes.</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Kürzen der Krallen"
                    services={["Fachgerechtes Einkürzen der Krallen"]}
                    price="Ab 8 Euro"
                />
                <ServiceItem
                    title="Pfotenpflege"
                    services={["Kürzen der Krallen und Ausscheren der Pfoten", "Pfotenbalsam"]}
                    price="Ab 18 Euro"
                />
                <ServiceItem
                    title="Ohrenpflege"
                    services={["Säuberung der Ohren"]}
                    price="Ab 8 Euro"
                />
                <ServiceItem
                    title="Augenpflege"
                    services={["Freischneiden der Augen"]}
                    price="Ab 8 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Ultraschall Zahnreinigung</CategoryTitle>
            <DentalHygieneInfo/>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Zahnbürste"
                    services={["Anschaffung der Zahnbürste", "Einlagerung und Verwendung für Ihren Hund"]}
                    price="8 Euro"
                />
                <ServiceItem
                    title="Zahnreinigung"
                    services={["Professionelle Ultraschall Zahnreinigung"]}
                    price="18 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Hundemassagen</CategoryTitle>
            <Subtitle>Eine Hundemassage kann zur Entspannung und Linderung von Muskelschmerzen und –
                Spannungen beitragen. Sie fördert die Durchblutung und verbessert das allgemeine
                Wohlbefinden des Hundes</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Hundemassage"
                    services={["Entspannende Massage für Ihren Hund"]}
                    price="Ab 20 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Thalasso-Therapie</CategoryTitle>
            <ThalassoInfo/>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Thalasso-Therapie - Kleiner Hund"
                    services={["Vorbehandlung des Hundes", "Auftragen des Meerschlammes inkl. Ausgedehnter Massage", "Auswaschen des Schlammes", "Abschließendes Föhnen und trocknen"]}
                    price="Ab 64 Euro"
                />
                <ServiceItem
                    title="Thalasso-Therapie - Mittlerer Hund"
                    services={["Vorbehandlung des Hundes", "Auftragen des Meerschlammes inkl. Ausgedehnter Massage", "Auswaschen des Schlammes", "Abschließendes Föhnen und trocknen"]}
                    price="Ab 74 Euro"
                />
                <ServiceItem
                    title="Thalasso-Therapie - Großer Hund"
                    services={["Vorbehandlung des Hundes", "Auftragen des Meerschlammes inkl. Ausgedehnter Massage", "Auswaschen des Schlammes", "Abschließendes Föhnen und trocknen"]}
                    price="Ab 84 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Welpeneingewöhnung</CategoryTitle>
            <Subtitle>Die Welpeneingewöhnung ist ein wichtiger Schritt für die Entwicklung des Welpen
                und die Beziehung zwischen Hund und Halter. In der Welpeneingewöhnung wird der
                Welpe an die Pflege und das Verhalten im Hundesalon gewöhnt.</Subtitle>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Entfilzung</CategoryTitle>
            <Subtitle>Ich entfilze Hunde nur im Rahmen einer Premiumpflege. In seltenen Fällen mache ich hin
                und wieder eine Ausnahme, dann aber nur zum Wohl Ihres Tieres.</Subtitle>
        </ServiceCategory>
        <hr/>
        <Subtitle>
            Jeder Hund ist so individuell, wie die Leistungen, die für ihn zu erbringen sind. Aus diesem
            Grund berechne ich meine Leistungen nach tatsächlichem Arbeits- bzw. Zeitaufwand.
            Dabei gehen wir vom günstigsten Fall aus.
        </Subtitle>
        <hr/>
        <FAQInfo/>
    </Container>
);

export default ServiceList;

import React from 'react';
import styled from 'styled-components';

const InfoWrapper = styled.div`
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #666;
    text-align: center;
`;

const InfoTitle = styled.h4`
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const InfoText = styled.p`
    margin-bottom: 0.5rem;
`;

const InfoList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
`;

const InfoListItem = styled.li`
    margin-bottom: 0.3rem;
`;

const FAQInfo = () => (
    <InfoWrapper>
        <InfoTitle>Die häufig gestellte Fragen und Antworten</InfoTitle>

        <InfoTitle>Was kostet die Pflege und was erhöht den Preis?</InfoTitle>
        <InfoList>
            <InfoListItem>- Der Preis ist individuell abhängig von der Größe und des Fellpflegezustandes</InfoListItem>
            <InfoListItem>- Der Preis erhöht sich durch verfilztes Fell, festsitzende Unterwolle, stark verschmutztes Fell, unruhige Hunde</InfoListItem>
        </InfoList>

        <InfoTitle>Warum Baden und Bürsten?</InfoTitle>
        <InfoList>
            <InfoListItem>- Für ein optimales Ergebnis, leichte Kämmbarkeit und Verfilzungen vorzubeugen</InfoListItem>
            <InfoListItem>- Um Haut und Fell von Umweltablagerungen zu befreien</InfoListItem>
        </InfoList>

        <InfoTitle>Darf ich bei der Behandlung dabei bleiben?</InfoTitle>
        <InfoList>
            <InfoListItem>- Gerne können Sie dabei bleiben,</InfoListItem>
            <InfoListItem>- Andernfalls ist Ihr Hund verunsichert und versteht nicht, weshalb Sie ihn nicht aus der Situation herausholen</InfoListItem>
            <InfoListItem>- Infolgedessen wird er unruhig, die Behandlung nimmt mehr Zeit in Anspruch und die Kosten erhöhen sich</InfoListItem>
            <InfoListItem>- Um bestmöglich Vertrauen zu Ihrem Hund aufzubauen und seine volle Konzentration zu erlangen, empfehle ich, dass Sie uns Ihre Fellnase anvertrauen</InfoListItem>
        </InfoList>

        <InfoTitle>Muss ich einen Termin vereinbaren?</InfoTitle>
        <InfoText>
            - Jeder Hund bekommt ein ausgiebiges Zeitfenster, damit keine Stresssituation durch den Folgetermin entsteht
        </InfoText>

        <InfoTitle>Wird mein Hund gesichert?</InfoTitle>
        <InfoText>
            - Um Ihrem Hund Halt und Sicherheit zu geben, lege ich ihm eine Schlaufe an, um einen möglichen Sprung vom Tisch und damit verbundene Verletzungen zu umgehen
        </InfoText>

        <InfoTitle>Wie kann ich meinen Hund auf den Salonbesuch vorbereiten?</InfoTitle>
        <InfoList>
            <InfoListItem>- Regelmässig Bürsten</InfoListItem>
            <InfoListItem>- Vor dem Termin Gassi gehen</InfoListItem>
            <InfoListItem>- Ich empfehle den Hund kurz vor dem Termin nicht zu füttern, um eine durch Aufregung ausgelöste Übelkeit zu vermeiden</InfoListItem>
        </InfoList>
    </InfoWrapper>
);

export default FAQInfo;

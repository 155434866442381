import React from 'react';
import styled from 'styled-components';
import image from '../assets/images/about.jpg';

const AboutContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 75%;
    border-radius: 8px;
    
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const AboutTitle = styled.h1`
    text-align: center;
    font-size: 3rem;
    margin-left: 1rem;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const AboutText = styled.p`
    text-align: justify;
    margin: 1rem 2rem;
    line-height: 1.6;

    @media (max-width: 768px) {
        text-align: left;
        font-size: 1rem;
        margin: 1rem 1rem;
    }
`;

const AboutSignature = styled.p`
    font-size: 2rem;
    text-align: center;
    margin: 1rem 2rem;
    line-height: 1.6;

    @media (max-width: 768px) {
        font-size: 1rem;
        margin: 1rem 1rem;
    }
`;

const AboutImage = styled.img`
    width: 55%;
    height: auto;
    margin-top: 1rem;

    @media (max-width: 768px) {
        margin-top: 1rem;
        order: 1;
    }
`;

const About = () => (
    <AboutContainer>
        <div>
            <AboutTitle>Liebe Hundefreunde</AboutTitle>
            <AboutText>
                Ich bin Beate und mein Traum eines eigenen Hundesalon ist wahr geworden, durch
                meine beiden treuen Begleiter, Luna und Alwa.
            </AboutText>
            <AboutText>
                Luna und Alwa sind nicht nur meine geliebten Hunde, sondern auch die Inspiration
                hinter meiner Leidenschaft für Hundefrisuren. Als ich anfing sie selbst zu pflegen und zu
                stylen, entdeckte ich meine Liebe und mein Talent für das Hundegrooming. Ihre
                bedingungslose Liebe und Geduld während meiner Lernphase haben mich dazu
                ermutigt, meinen Weg als professionelle Hundefriseurin einzuschlagen.
            </AboutText>
            <AboutText>
                Die Entscheidung, die Ausbildung zur Hundefriseurin zu absolvieren, war für mich der
                nächste logische Schritt, um meine Fähigkeiten zu perfektionieren und mein Wissen zu
                vertiefen.
            </AboutText>
            <AboutText>
                In meinem Hundesalon möchte ich nicht nur einen Ort der Schönheit, sondern auch des
                Wohlbefindens schaffen. Neben einer Vielzahl von Pflege- und Styling-Services biete ich
                auch entspannende Hundemassagen an, um euren vierbeinigen Lieblingen das
                ultimative Erlebnis zu ermöglichen.
                Jeder Hund, der meinen Salon betritt, soll sich wie zu Hause fühlen und in einer
                entspannten Atmosphäre verwöhnt werden.
                Mit viel Liebe, Hingabe und Fachkenntnis stehe ich bereit, um euren vierbeinigen
                Lieblingen den bestmöglichen Service zu bieten. Egal ob es um eine klassische
                Fellpflege, eine wohltuende Massage oder eine entspannende Wellnessbehandlung geht,
                bei mir ist euer Hund in den besten Händen.
                Ich freue mich darauf, euch und eure Fellnasen bald persönlich in meinem Salon
                begrüßen zu dürfen und gemeinsam eine wundervolle Zeit zu verbringen.
            </AboutText>
            <AboutSignature>
                Eure Hundefriseurin Beate
            </AboutSignature>
        </div>
        <AboutImage src={image} alt="Ich, Luna und Alwa"/>
    </AboutContainer>
);

export default About;

import React, {useState, useEffect} from "react";
import styled from "styled-components";

const Slider = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    height: 20%;
    background-color: #e9e9e7;
    margin: 2rem 0;
    `;

const Image = styled.img`
    max-width: 50%;
    max-height: 50%;
    border-radius: 1rem;
`;

const ImageSlider = ({images}) => {
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrent((current + 1) % images.length);
        }, 7000);
        return () => clearInterval(timer);
    }, [images, current]);

    return (
        <Slider>
            <Image src={images[current]} alt="slider" />
        </Slider>
    );
};

export default ImageSlider;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
    background: #c1afb2;
    color: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const NavLinks = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const NavLink = styled(Link)`
  color: white;
  margin: 0 1rem;
  font-weight: 500;

  &:hover {
    color: #333;
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const Header = () => (
    <Nav>
        <div>Bedlu Hundesalon</div>
        <NavLinks>
            <NavLink to="/" className="nav-link">Home</NavLink>
            <NavLink to="/services" className="nav-link">Services</NavLink>
            <NavLink to="/about" className="nav-link">Über mich</NavLink>
            <NavLink to="/contact" className="nav-link">Kontakt</NavLink>
        </NavLinks>
    </Nav>
);

export default Header;

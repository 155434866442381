import React from 'react';
import ServiceList from '../components/ServiceList';
import styled from "styled-components";
import "../styles/ServiceImage.css";
import image from '../assets/images/scheren_quer.jpg';

const ServiceTitle = styled.h1`
    text-align: center;
    font-size: 2rem;
`;

const ServiceSubtitle = styled.p`
    text-align: center;
    font-size: 1.5rem;
`;

const Services = () => (
    <div>
        <main>
            <section>
                <ServiceTitle>Mein Service</ServiceTitle>
                <ServiceSubtitle>Bei mir erwartet Sie ein breites Angebot an verschiedenen Services rund um ihren
                    Hund.</ServiceSubtitle>
                <div className="centered-image-container">
                    <img style={{borderRadius: "1rem"}} src={image} alt="BeDlu Hundesalon" className="centered-image"/>
                </div>
            </section>
        </main>
        <ServiceList/>
    </div>
);

export default Services;

import { createGlobalStyle } from 'styled-components';
import './fonts.css';

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html, body {
        height: 100%;
        min-height: 100vh;
        margin: 0;
        font-family: 'Roboto', sans-serif;
        color: #333;
        background-color: #e9e9e7;
    }

    #root {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .main-container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    main {
        flex: 1;
    }

    footer {
        margin-top: auto;
        background: #333;
        color: white;
        text-align: center;
        padding: 1rem;
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    /* Media Queries for Responsive Design */
    @media (max-width: 768px) {
        .main-container {
            padding: 0 0.5rem;
        }

        h1 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1rem;
        }

        .nav-link {
            display: block;
            margin: 0.5rem 0;
        }

        .centered-image {
            max-width: 100%;
            height: auto;
        }
    }
`;

export default GlobalStyles;

import React from 'react';
import styled from 'styled-components';

const InfoWrapper = styled.div`
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #666;
    text-align: center;
`;

const InfoTitle = styled.h4`
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const InfoText = styled.p`
    margin-bottom: 0.5rem;
`;

const ThalassoInfo = () => (
    <InfoWrapper>
        <InfoTitle>Habt Ihr schonmal von Thalasso für Hunde gehört?</InfoTitle>
        <InfoText>
            Auch Kurzhaar-Hunde genießen die Fellpflege; sie benötigen zwar häufig keinen Schnitt, aber schwelgen in der Zuwendung einer schönen Thalasso-Therapie.
            Insbesondere bei Hautproblemen wie Juckreiz und Allergien sowie bei Erkrankungen der Atemwege hat Thalasso mit seinen unterschiedlichen Schlämmen eine heilende Wirkung.
        </InfoText>
        <InfoText>
            Die natürlichen Wirkstoffe aus Nord- und Ostsee, das salzhaltige Meerwasser, aber auch Algen und Schlamm sind wahre Schätze des Meeres. Wenn sich also Euer Hund im nächsten Seeurlaub im Schlick wälzt, freut Euch darüber.
            Da das Meer aber nicht vor der Haustür liegt, könnt Ihr Eure Fellnase auch bei mir verwöhnen lassen.
        </InfoText>
        <InfoText>
            Ich benutze ausschließlich ökologische und speziell auf das Tier abgestimmte Produkte.
        </InfoText>
    </InfoWrapper>
);

export default ThalassoInfo;

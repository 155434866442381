import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import {Helmet} from "react-helmet";
import Imprint from "./pages/Imprint";
import Privicy from "./pages/Privicy";
import Contact from "./pages/Contact";

function App() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Helmet>
                <title>Home | BeDlu Hundesalon</title>
                <meta name="description"
                      content="BeDlu Hundesalon - Wir bieten ihnen die besten Services rund um ihren Hund"/>
                <meta name="keywords"
                      content="hundefriseur, hundesalon, moenchengladbach, mönchengladbach, hunde, hund, bedlu"/>
            </Helmet>
            <Router>
                <GlobalStyles/>
                <div className="main-container">
                    <Header/>
                    <main>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/services" element={<Services/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/imprint" element={<Imprint/>}/>
                            <Route path="/privacy" element={<Privicy/>}/>
                        </Routes>
                    </main>
                    <Footer/>
                </div>
            </Router>
        </div>
    );
}

export default App;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding: 2rem;
    max-width: 800px;
    margin: auto;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
`;

const Subtitle = styled.p`
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #666;
`;

const ContactInfo = styled.div`
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #555;
`;

const EmailButton = styled.a`
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    color: white;
    background-color: #007bff;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: background-color 0.2s;

    &:hover {
        background-color: #0056b3;
    }
`;

const Contact = () => (
    <Container>
        <Title>So können Sie uns kontaktieren</Title>
        <Subtitle>Wir haben ihr Interesse geweckt? Gerne können Sie uns zwecks Terminbuchung anrufen oder eine Email hinterlassen</Subtitle>
        <ContactInfo>
            <p>BeDlu Hundesalon</p>
            <p>An den Fichten 1, 41199 Mönchengladbach</p>
            <p>Telefon: +49 (0) 2166/1895699</p>
            <p>Mobil: +49 (0) 173/3887227</p>
            <p>Email: bedlu-hundesalon@gmx.de</p>
        </ContactInfo>
        <EmailButton href="mailto:bedlu-hundesalon@gmx.de?subject=Terminanfrage%20ueber%20Website">Schreib uns eine Mail!</EmailButton>
    </Container>
);

export default Contact;

import React from 'react';
import styled from 'styled-components';

const InfoWrapper = styled.div`
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #666;
    text-align: center;
`;

const InfoTitle = styled.h4`
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const InfoText = styled.p`
    margin-bottom: 0.5rem;
`;

const InfoList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
`;

const InfoListItem = styled.li`
    margin-bottom: 0.3rem;
`;

const PricingInfo = () => (
    <InfoWrapper>
        <InfoTitle>Der Endpreis ergibt sich aus dem Zustand</InfoTitle>
        <InfoList>
            <InfoListItem>- des Fells / Fellbeschaffenheit</InfoListItem>
            <InfoListItem>- dem Pflegezustand</InfoListItem>
            <InfoListItem>- die Größe</InfoListItem>
            <InfoListItem>- der Mitarbeit Ihres Hundes, also dem echten Zeitaufwand, den ich letztendlich benötige</InfoListItem>
        </InfoList>
        <InfoText>
            Bitte haben Sie Verständnis, dass ich ohne den Hund gesehen zu haben keine Endpreise nennen kann.
        </InfoText>
    </InfoWrapper>
);

export default PricingInfo;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
    background: #c1afb2;
    color: white;
    text-align: center;
    padding: 1rem;
    width: 100%;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
`;

const NavLink = styled(Link)`
  color: white;
  margin: 0 1rem;
  font-weight: 500;

  &:hover {
    color: #333;
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const Footer = () => (
    <FooterContainer>
        &copy; 2024 Bedlu Hundesalon. Alle Rechte vorbehalten.
        <NavLink to="/imprint">Impressum</NavLink>
        <NavLink to="/privacy">Datenschutz</NavLink>
    </FooterContainer>
);

export default Footer;
